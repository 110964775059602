body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.nav-link {
  text-decoration: none;
  color: #000;
}
.nav-link:not(:last-child) {
  margin-right: 20px;
}
.nav-link.active {
  color: rgb(172, 67, 167);
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.app-main__inner {
  padding: 1.5rem 1.5rem 0;
  flex: 1;
}

.card.mb-3 {
  margin-bottom: 1.5rem !important;
}

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-width: 0;
  transition: all 0.2s;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.5rem;
  height: 3.5rem;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.5rem;
  height: 3.5rem;
}
.card-header,
.card-title {
  text-transform: uppercase;
  color: rgba(13, 27, 62, 0.7);
  font-weight: bold;
  font-size: 0.88rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(26, 54, 126, 0.125);
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  overflow: hidden;
  background: #e9ecef;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  justify-content: space-between;
}

.input-container {
  position: relative;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

/* .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
} */

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.form-control {
  transition: all 0.2s;
  max-width: 172px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.clear-icon {
  font-size: 22px;
  position: absolute;
  top: 21%;
  right: 10px;
  left: -7px;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a9bcee;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(63 106 216 / 25%);
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  border-radius: 0.25rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
}

.btn-primary {
  color: #fff;
  background-color: #3f6ad8;
  border-color: #3f6ad8;
}

table.dataTable {
  clear: both;
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

.table > thead {
  vertical-align: bottom;
}

.table > tbody {
  vertical-align: inherit;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.fa-user:before {
  content: "\f007";
}

.table th,
.table td {
  vertical-align: middle;
  box-sizing: border-box;
}

.vertical {
  width: 64px;
  /* overflow: auto;
  white-space: nowrap; */
}
td {
  white-space: nowrap;
}
td span {
  display: block;
  width: 100%;
  overflow: hidden;
}
.vertical span {
  writing-mode: vertical-rl;
  text-orientation: sideways-right;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  /* background-color: rgba(0, 0, 0, 0.05); */
  color: var(--bs-table-striped-color);
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

/* .table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.05);
} */

.h-100 {
  height: 100% !important;
}

.rs-picker-daterange-header {
  display: none;
}

.fa-user:hover {
  color: #000;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #16aaff;
  border-color: #16aaff;
}

.btn-outline-info {
  color: #16aaff;
  border-color: #16aaff;
}

table {
  table-layout: fixed;
}
td {
  max-height: 64px !important;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: #e0f3ff !important;
  color: var(--bs-table-hover-color);
}

.custom-br {
  border-radius: 1rem !important;
}
.text-title {
  font-size: 1.25rem;
}
.text-title span {
  color: #4d97e6;
}
.value {
  font-size: 1.75rem;
  color: #4d97e6;
}
.col-2-25 {
  flex: 0 0 18.75%;
  width: 18.75%;
}
.h-100 {
  height: 100% !important;
}

.chartjs-render-monitor {
  /* height: 100% !important; */
  width: 100% !important;
  object-fit: contain;
}

.chart-pie__container {
  height: 50%;
  width: 90%;
  margin: auto;
}

.half-size-chart {
  width: 90%;
}

.form-label {
  margin-bottom: 0 !important;
}
.form-check,
.form-check * {
  cursor: pointer !important;
}

.btn-info {
  --bs-btn-color: #fff !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-active-color: #fff !important;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}