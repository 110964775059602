.table {
  width: 100%;
}

.table thead th {
  background-color: #141515;
  color: white;
  font-weight: bold;
  border: 1px solid rgb(47, 47, 47);
  border-collapse: collapse;
  text-align: center;

}

.tableStriped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}

.table td,
.table th {
  padding: 0.75rem;
  border: 1px solid rgb(36, 36, 36);
  border-collapse: collapse;
  text-align: center;
  /* width: 200px;
  word-wrap: break-word; */
}

.tableResponsive {
  overflow-x: auto;
}
.red{
  background-color: red !important;
  color: #f8f9fa;
}
.green{
  background-color: green !important;
  color: #ffff;
}

@media (max-width: 575.98px) {
  .table td,
  .table th {
    white-space: nowrap;
  }
}
